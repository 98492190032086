exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hire-us-tsx": () => import("./../../../src/pages/hire-us.tsx" /* webpackChunkName: "component---src-pages-hire-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-process-tsx": () => import("./../../../src/pages/our-process.tsx" /* webpackChunkName: "component---src-pages-our-process-tsx" */),
  "component---src-pages-our-work-index-tsx": () => import("./../../../src/pages/our-work/index.tsx" /* webpackChunkName: "component---src-pages-our-work-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-cloud-engineering-tsx": () => import("./../../../src/pages/services/cloud-engineering.tsx" /* webpackChunkName: "component---src-pages-services-cloud-engineering-tsx" */),
  "component---src-pages-services-cloud-strategy-and-assessment-tsx": () => import("./../../../src/pages/services/cloud-strategy-and-assessment.tsx" /* webpackChunkName: "component---src-pages-services-cloud-strategy-and-assessment-tsx" */),
  "component---src-pages-services-cloud-support-tsx": () => import("./../../../src/pages/services/cloud-support.tsx" /* webpackChunkName: "component---src-pages-services-cloud-support-tsx" */),
  "component---src-pages-services-containers-and-kubernetes-tsx": () => import("./../../../src/pages/services/containers-and-kubernetes.tsx" /* webpackChunkName: "component---src-pages-services-containers-and-kubernetes-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-monitoring-and-observability-tsx": () => import("./../../../src/pages/services/monitoring-and-observability.tsx" /* webpackChunkName: "component---src-pages-services-monitoring-and-observability-tsx" */),
  "component---src-pages-services-your-startup-partner-tsx": () => import("./../../../src/pages/services/your-startup-partner.tsx" /* webpackChunkName: "component---src-pages-services-your-startup-partner-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-templates-our-work-index-js": () => import("./../../../src/templates/OurWork/index.js" /* webpackChunkName: "component---src-templates-our-work-index-js" */)
}

